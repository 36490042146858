// PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
import { currentDevice } from '../core/utils/currentDevice'

import { readCookie, setCookie } from './cookie_manager'

const TEST_NAME = 'test_reb_header'

let isTestRebHeader = IB.urlParser.getParam(TEST_NAME) === 'true' || readCookie(TEST_NAME) === 'true'

if (IB.urlParser.getParam(TEST_NAME) === 'true') {
  setCookie(TEST_NAME, true, 1)
} else if (IB.urlParser.getParam(TEST_NAME) === 'false') {
  //setCookie(TEST_NAME, false, -1)
  IB.cookieManager.deleteCookies([TEST_NAME])
  isTestRebHeader = false
}

export const initTestRebHeader =
  currentDevice.isDesktop && (isTestRebHeader || IB.currentPageType.isYouDoYou() === true)
